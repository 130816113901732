import { For, Show } from "solid-js";
import {
  AutopromosRenderer,
  mayHaveShortcode,
} from "~/components/shared/Autopromos/AutopromosRenderer";
import LandingSectionVisual from "~/components/Landing/Components/LandingSectionVisual";

import type { Landing, SectionLanding } from "~/types/drupal_jsonapi";

import "./LandingSectionsDesktop.css";
import "~/components/shared/WysiwygComponents.css";

export default function LandingSectionsDesktop(props: {
  sections: SectionLanding[];
  landing: Landing;
}) {
  return (
    <>
      <div class="sections-desktop">
        <For each={props.sections}>
          {(section: SectionLanding) => (
            <SectionContent section={section} landing={props.landing} />
          )}
        </For>
      </div>
    </>
  );
}

function SectionContent(props: { section: SectionLanding; landing: Landing }) {
  return (
    <>
      <section
        class={`section ${props.section.field_image_align ?? props.section.field_image_align}`}
        data-test="paragraph"
      >
        <Show when={props.section.field_image?.uri?.url}>
          <LandingSectionVisual
            field_image={props.section.field_image!}
            field_image_link={props.section.field_image_link}
          />
        </Show>
        <div class="text-part">
          <Show when={props.section.field_title}>
            <h3 class="title">{props.section.field_title}</h3>
          </Show>
          <Show
            when={props.section.field_text && props.section.field_text.value}
          >
            <Show
              when={mayHaveShortcode(props.section.field_text!.value)}
              fallback={<div innerHTML={props.section.field_text!.value} />}
            >
              <AutopromosRenderer
                text={props.section.field_text!.value}
                landing={props.landing}
                fromFieldName="field_text"
              />
            </Show>
          </Show>
        </div>
      </section>
    </>
  );
}
