import { Show } from "solid-js";
import imgProxy from "~/utils/imgproxy";

import type { ImageField } from "~/types/drupal_jsonapi";
import { useMediaOverlayContext } from "~/contexts/MediaOverlayContext";

export default function LandingSectionVisual(props: {
  field_image_link?: string;
  field_image: ImageField;
}) {
  const [, { setMediaOverlay }] = useMediaOverlayContext();

  return (
    <picture class="visual">
      <Show
        when={props.field_image_link}
        fallback={
          <button
            type="button"
            class="btn"
            aria-label="Voir l’image"
            onClick={() => setMediaOverlay("images", [props.field_image])}
          >
            <SectionVisual field_image={props.field_image} />
          </button>
        }
      >
        <a href={props.field_image_link} target="_blank" class="btn">
          <SectionVisual field_image={props.field_image} />
        </a>
      </Show>
    </picture>
  );
}

function SectionVisual(props: { field_image: ImageField }) {
  return (
    <img
      src={imgProxy(
        props.field_image.uri.url,
        "size:400/resizing_type:fill/gravity:nowe",
      )}
      alt={props.field_image.meta?.alt}
      width={props.field_image.meta?.width}
      height={props.field_image.meta?.height}
      loading="lazy"
    />
  );
}
