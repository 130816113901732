import FirstScreenMainImage from "~/components/Landing/Components/FirstScreenMainImage";
import { Show } from "solid-js";
import { useMediaOverlayContext } from "~/contexts/MediaOverlayContext";
import { useEventsContext } from "~/contexts/EventsContext";
import { hasNoEscape } from "~/utils/no_escape";
import { useViewportSizeContext } from "~/contexts/ViewportSizeContext";
import MediaContainer from "~/components/Landing/Components/MediaContainer";
import CTAs from "~/components/Landing/Components/CTAs";

import type { Landing } from "~/types/drupal_jsonapi";

import "./FirstScreen.css";

export default function FirstScreen(props: { landing: Landing }) {
  const [, { setMediaOverlay }] = useMediaOverlayContext();
  const [, { sendClick }] = useEventsContext();
  const [viewportSizeProvider] = useViewportSizeContext();

  const landingsNNids = [
    /* DEV : 2179 2180 2181 / PREPROD : 1832497 1832498 1832499 / PROD : 1832647 1832648 1832649 */
    /* LOCAL 2178 */
    2179,
    2180, 2181, 1832497, 1832498, 1832499, 1832647, 1832648, 1832649,
  ];

  return (
    <>
      <section
        class="full-visual"
        classList={{
          "is-nouveau-neuf": landingsNNids.includes(
            props.landing.drupal_internal__nid,
          ),
        }}
        data-ga-zone="first-screen"
        data-test="first-screen"
      >
        <div class="visual">
          <FirstScreenMainImage
            imageMobile={props.landing.field_image_mobile}
            imageDesktop={props.landing.field_image_desktop}
          />
          <Show when={hasNoEscape()}>
            <span class="cog-logo">
              <img
                src="/images/logo-cogedim.svg"
                alt="Logo Cogedim"
                height="40"
                width="40"
              />
              Cogedim
            </span>
          </Show>

          <Show
            when={
              props.landing.field_gallery_disp &&
              props.landing.field_image_desktop &&
              props.landing.field_image_desktop!.length > 0
            }
          >
            <button
              type="button"
              onClick={() => {
                sendClick("main-image", "first-screen");
                setMediaOverlay("images", props.landing.field_image_desktop);
              }}
              class="direct-open-gallery"
              aria-label="Afficher la galerie"
              data-test="btn-gallery"
            />
          </Show>
        </div>

        <div class="info-box">
          <div class="content-part info-box-content">
            <div class="title-group" data-test="header">
              <Show
                when={
                  props.landing.field_vat_inc_reduced ||
                  props.landing.field_vat_inc_mastered ||
                  props.landing.field_vat_inc_brs ||
                  props.landing.field_pinel ||
                  props.landing.field_pinel_plus ||
                  props.landing.field_lmnp_vat_inc ||
                  props.landing.field_lmnp_vat_ex ||
                  props.landing.field_bare_ownership ||
                  props.landing.field_patrimonial ||
                  props.landing.animation
                }
              >
                <ul class="regulation" data-test="regulations">
                  <Show when={props.landing.field_vat_inc_reduced}>
                    <li>TVA réduite</li>
                  </Show>
                  <Show when={props.landing.field_vat_inc_mastered}>
                    <li>Prix maîtrisés</li>
                  </Show>
                  <Show when={props.landing.field_vat_inc_brs}>
                    <li>BRS</li>
                  </Show>
                  <Show when={props.landing.field_pinel}>
                    <li>Dispositif Pinel</li>
                  </Show>
                  <Show when={props.landing.field_pinel_plus}>
                    <li>Dispositif Pinel +</li>
                  </Show>
                  <Show when={props.landing.field_lmnp_vat_inc}>
                    <li>LMNP</li>
                  </Show>
                  <Show when={props.landing.field_lmnp_vat_ex}>
                    <li>LMNP géré</li>
                  </Show>
                  <Show when={props.landing.field_bare_ownership}>
                    <li>Nue-propriété</li>
                  </Show>
                  <Show when={props.landing.field_patrimonial}>
                    <li>Patrimonial</li>
                  </Show>
                  <Show when={props.landing.animation}>
                    <li class="exclusive-offer" data-test="offer">
                      Offre exclusive
                    </li>
                  </Show>
                </ul>
              </Show>

              <h1 innerHTML={props.landing.title} />

              <Show when={props.landing.field_catchline}>
                <p class="catchline" data-test="catchline">
                  {props.landing.field_catchline}
                </p>
              </Show>

              <Show when={props.landing.field_disp_summary}>
                <div class="summary" data-test="summary">
                  <span innerHTML={props.landing.typology_summary} />{" "}
                  <span class="price" innerHTML={props.landing.price_summary} />
                </div>
              </Show>

              <Show when={viewportSizeProvider.viewPortIsLessThan768}>
                <CTAs landing={props.landing} />
              </Show>
            </div>

            <div class="media-ctas-group">
              <MediaContainer landing={props.landing} />
              <Show when={!viewportSizeProvider.viewPortIsLessThan768}>
                <CTAs landing={props.landing} desktopOnly />
              </Show>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
