import { useEventsContext } from "~/contexts/EventsContext";
import { useModalFormContext } from "~/contexts/ModalFormContext";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import { useViewportSizeContext } from "~/contexts/ViewportSizeContext";
import { Match, Show, Switch } from "solid-js";

import type { Landing } from "~/types/drupal_jsonapi";

export default function CTAs(props: {
  landing: Landing;
  desktopOnly?: boolean;
}) {
  const [, { sendShowEvent, setTempZone }] = useEventsContext();
  const [, { openModalForm, setModalFormHeadline }] = useModalFormContext();
  const settings = useDrupalSettingsContext();
  const [, { sendClick }] = useEventsContext();
  const [viewportSizeProvider] = useViewportSizeContext();

  return (
    <>
      <div class="ctas">
        <Show
          when={
            props.landing.field_sections_landing &&
            props.landing.field_sections_landing.length > 0 &&
            props.landing.programs &&
            props.landing.programs.length > 0 &&
            props.desktopOnly &&
            viewportSizeProvider.viewPortIsMoreThan768
          }
        >
          <a
            class="btn anchor"
            href="#programs-landing"
            data-test="btn-programs"
          >
            Découvrir les résidences
          </a>
        </Show>
        <Switch>
          <Match when={props.landing.field_leaflet?.uri?.url}>
            <Show
              when={settings.form_leaflet_bypass}
              fallback={
                <button
                  type="button"
                  class="btn"
                  data-test="cta-leaflet"
                  onClick={(e) => {
                    sendShowEvent(`leaflet`, e, {
                      nid: props.landing.drupal_internal__nid,
                    });
                    openModalForm!("leaflet", { landing: props.landing });
                    setModalFormHeadline!("Télécharger la brochure");
                  }}
                >
                  Télécharger la brochure
                </button>
              }
            >
              <a
                href={props.landing.field_leaflet?.uri?.url}
                class="btn"
                data-test="btn-leaflet"
                target="_blank"
                onClick={() => {
                  sendClick(`btn-leaflet`, "first-screen");
                }}
                rel="noindex nofollow"
              >
                Télécharger la brochure
              </a>
            </Show>
          </Match>
          <Match
            when={
              props.landing.field_disp_anchor &&
              props.landing.field_anchor_text &&
              props.landing.field_form?.field_display
            }
          >
            <a class="btn anchor" href="#form-in-page-wrapper">
              {props.landing.field_anchor_text}
            </a>
          </Match>
        </Switch>
        <button
          type="button"
          class="btn"
          data-test="cta-advisor"
          onClick={(e) => {
            setTempZone(e);
            openModalForm!("advisor", { landing: props.landing });
            setModalFormHeadline!("Contacter un conseiller");
          }}
        >
          Contacter un conseiller
        </button>
      </div>
    </>
  );
}
