import { lazy, Show, Suspense } from "solid-js";
import { useLotActive } from "~/contexts/LotActiveContext";
import FirstScreen from "~/components/Landing/Components/FirstScreen";
import Breadcrumb from "~/components/shared/Breadcrumb";
import Introduction from "~/components/Landing/Components/Introduction";
import StackBar from "~/components/Landing/Components/StackBar";
import LandingPrograms from "~/components/Landing/Components/LandingPrograms";
import CommercialOffer from "~/components/shared/CommercialOffer";
import LandingFormInPage from "~/components/Landing/Components/LandingFormInPage";
import ContentSeoText from "~/components/shared/ContentSeoText";
import { useViewportSizeContext } from "~/contexts/ViewportSizeContext";
import LandingSectionsMobile from "~/components/Landing/Components/LandingSectionsMobile";
import LandingSectionsDesktop from "~/components/Landing/Components/LandingSectionsDesktop";
import { hasNoEscape } from "~/utils/no_escape";
import {
  AutopromosRenderer,
  hasMultipleShortcodes,
} from "~/components/shared/Autopromos/AutopromosRenderer";

import type { Landing } from "~/types/drupal_jsonapi";

import "./Landing.css";
import PromosSegmentsMkg from "~/components/shared/PromosSegmentsMkg";
import GtmVars from "../shared/Trackers/GtmVars";

const LotDrawer = lazy(
  () => import("~/components/Program/Components/LotDrawer"),
);

export default function Landing(props: { landing: Landing }) {
  const [lotProvider] = useLotActive();

  const [viewportSizeProvider] = useViewportSizeContext();

  const gtmValues = () => {
    const breadcrumbItems = props.landing.breadcrumb.slice(1).toReversed();
    return {
      "all.pageType": "listing page",
      "all.mainCategory": breadcrumbItems.at(0)?.text ?? "Non défini",
      "all.category": breadcrumbItems.at(1)?.text ?? "Non défini",
      "all.subCategory": breadcrumbItems.at(2)?.text ?? "Non défini",
      "all.subsubCategory": breadcrumbItems.at(3)?.text ?? "Non défini",
      "all.subsubsubCategory": breadcrumbItems.at(4)?.text ?? "Non défini",
      ...props.landing.gtm_vars,
    };
  };

  return (
    <>
      <GtmVars values={gtmValues()} />
      <article class="node-landing">
        <Show when={lotProvider.wrapper && lotProvider.lot} keyed>
          <Suspense>
            <LotDrawer wrapper={lotProvider.wrapper!} lot={lotProvider.lot!} />
          </Suspense>
        </Show>

        <FirstScreen landing={props.landing} />
        <StackBar landing={props.landing} />

        <div class="content-part">
          <Show
            when={
              props.landing.field_promos_segments_mkg &&
              props.landing.field_promos_segments_mkg.length > 0
            }
          >
            <PromosSegmentsMkg
              promosSegmentsMkg={props.landing.field_promos_segments_mkg!}
            />
          </Show>

          <Show
            when={
              props.landing.field_main_text?.value ||
              (props.landing.field_strengths &&
                props.landing.field_strengths?.length > 0)
            }
          >
            <Introduction
              mainText={props.landing.field_main_text?.value}
              strengths={props.landing.field_strengths}
            />
          </Show>

          <Show when={props.landing.animation}>
            <CommercialOffer animation={props.landing.animation!} />
          </Show>

          <Show
            when={
              props.landing.field_sections_landing &&
              props.landing.field_sections_landing.length > 0
            }
          >
            <section class="landing-sections" data-test="paragraphs">
              <Show
                when={viewportSizeProvider.viewPortIsLessThan768}
                fallback={
                  <LandingSectionsDesktop
                    landing={props.landing}
                    sections={props.landing.field_sections_landing!}
                  />
                }
              >
                <LandingSectionsMobile
                  landing={props.landing}
                  sections={props.landing.field_sections_landing!}
                />
              </Show>
            </section>
          </Show>
        </div>

        <Show when={props.landing.field_form?.field_display}>
          <LandingFormInPage landing={props.landing} />
        </Show>

        <LandingPrograms landing={props.landing} />
      </article>
      <Show when={props.landing.field_ads && !hasNoEscape()}>
        <section
          class="autopromos-container"
          data-ga-zone="ads"
          data-test="autopromos"
          classList={{
            single: !hasMultipleShortcodes(props.landing.field_ads!),
            multiple: hasMultipleShortcodes(props.landing.field_ads!),
          }}
        >
          <AutopromosRenderer
            text={props.landing.field_ads!}
            landing={props.landing}
            fromFieldName="field_ads"
          />
        </section>
      </Show>
      <Show
        when={
          props.landing.field_seo_block_body &&
          props.landing.field_seo_block_body.length > 0
        }
      >
        <ContentSeoText
          title={props.landing.field_seo_block_title}
          bodies={props.landing.field_seo_block_body!}
        />
      </Show>

      <Breadcrumb items={props.landing.breadcrumb.slice(1)} />
    </>
  );
}
