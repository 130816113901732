import { For, Show } from "solid-js";
import {
  AutopromosRenderer,
  mayHaveShortcode,
} from "~/components/shared/Autopromos/AutopromosRenderer";
import LandingSectionVisual from "~/components/Landing/Components/LandingSectionVisual";

import type { Landing, SectionLanding } from "~/types/drupal_jsonapi";

import "./LandingSectionsMobile.css";
import "~/components/shared/WysiwygComponents.css";

import IconExpandMore from "~/img/icons/expand_more.svg";
import Accordion from "@corvu/accordion";

export default function LandingSectionsMobile(props: {
  sections: SectionLanding[];
  landing: Landing;
}) {
  return (
    <>
      <div class="sections-mobile">
        <Accordion collapseBehavior="hide">
          <For each={props.sections}>
            {(section: SectionLanding) => (
              <Show
                when={section.field_title}
                fallback={
                  <section class="section no-title" data-test="paragraph">
                    <SectionContent
                      section={section as SectionLanding}
                      landing={props.landing}
                    />
                  </section>
                }
              >
                <Accordion.Item
                  as="section"
                  class="collapse-container section"
                  data-test="paragraph"
                >
                  <Accordion.Trigger as="h3" class="collapse-header">
                    {section.field_title}
                    <i aria-hidden="true" class="cog-icon">
                      <IconExpandMore />
                    </i>
                  </Accordion.Trigger>
                  <Accordion.Content>
                    <div class="collapse-content">
                      <SectionContent
                        section={section as SectionLanding}
                        landing={props.landing}
                      />
                    </div>
                  </Accordion.Content>
                </Accordion.Item>
              </Show>
            )}
          </For>
        </Accordion>
      </div>
    </>
  );
}

function SectionContent(props: { section: SectionLanding; landing: Landing }) {
  return (
    <>
      <Show when={props.section.field_image?.uri?.url}>
        <LandingSectionVisual
          field_image={props.section.field_image!}
          field_image_link={props.section.field_image_link}
        />
      </Show>
      <Show when={props.section.field_text && props.section.field_text.value}>
        <Show
          when={mayHaveShortcode(props.section.field_text!.value)}
          fallback={
            <div
              class="text-part"
              innerHTML={props.section.field_text!.value}
            />
          }
        >
          <div class="text-part">
            <AutopromosRenderer
              text={props.section.field_text!.value}
              landing={props.landing}
              fromFieldName="field_text"
            />
          </div>
        </Show>
      </Show>
    </>
  );
}
