import type { ParagraphStrength } from "~/types/drupal_jsonapi";
import { For, Show } from "solid-js";

import "./Introduction.css";

type IntroductionProps = {
  mainText?: string;
  strengths?: ParagraphStrength[];
};

export default function Introduction(props: IntroductionProps) {
  return (
    <>
      <section class="introduction-part" data-test="introduction">
        <Show when={props.mainText}>
          <div
            class="text-part"
            data-test="main-text"
            innerHTML={props.mainText}
          />
        </Show>
        <Show when={props.strengths && props.strengths?.length > 0}>
          <div class="strengths" data-test="strengths">
            <For each={props.strengths}>
              {(strength) => {
                return (
                  <div class="strength" data-test="strength">
                    <img
                      src={`/images/pictos-strength/${strength.field_picto}`}
                      alt=""
                    />{" "}
                    <span innerHTML={strength.field_label} />
                  </div>
                );
              }}
            </For>
          </div>
        </Show>
      </section>
    </>
  );
}
