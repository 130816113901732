import { clientOnly } from "@solidjs/start";
import { Show } from "solid-js";
import { utmStoreGet } from "~/lib/utm_store";

import type { Landing } from "~/types/drupal_jsonapi";

import "./LandingFormInPage.css";
import { getFormEventNameFromCrmObject } from "~/components/Program/Components/ProgramFormInPage";

const FormInPage = clientOnly(() => import("../../Forms/InPage"));

export default function LandingFormInPage(props: { landing: Landing }) {
  const hiddenFields = () => {
    return {
      base_email: utmStoreGet("utm_source"),
      campaign: utmStoreGet("utm_campaign"),
      source: utmStoreGet("utm_source"),
      medium: utmStoreGet("utm_medium"),
      term: utmStoreGet("utm_term"),
      content: utmStoreGet("utm_content"),
      node_nid: props.landing.drupal_internal__nid,
    };
  };

  return (
    <>
      <section class="form-in-page-wrapper">
        <div class="form-in-page form-in-page-white" data-test="form-in-page">
          <Show when={props.landing.field_form?.field_title}>
            <h2>{props.landing.field_form!.field_title}</h2>
          </Show>
          <div data-ga-zone="form">
            <Show when={props.landing.field_form?.field_catchline}>
              <p class="catchline" data-test="catchline">
                {props.landing.field_form!.field_catchline}
              </p>
            </Show>
            <FormInPage
              landing={props.landing}
              formConfig={props.landing.field_form}
              hiddenFields={{ form_id: "in-page", ...hiddenFields() }}
              formNameForEvent={getFormEventNameFromCrmObject(
                props.landing.field_form!.field_crm_object,
              )}
            />
          </div>
        </div>
      </section>
    </>
  );
}
