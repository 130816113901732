import { Match, Show, Switch } from "solid-js";
import { useMediaOverlayContext } from "~/contexts/MediaOverlayContext";
/*import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";*/
import { useEventsContext } from "~/contexts/EventsContext";

import type { Landing } from "~/types/drupal_jsonapi";

import IconPlayCircle from "~/img/icons/play_circle.svg";
import Icon3dRotation from "~/img/icons/3d_rotation.svg";
import IconPhotoLibrary from "~/img/icons/photo_library.svg";
import IconLocationOn from "~/img/icons/location_on.svg";
import IconDescription from "~/img/icons/description.svg";

type MediaContainerProps = {
  landing: Landing;
};

export default function MediaContainer(props: MediaContainerProps) {
  /*const settingsContext = useDrupalSettingsContext();*/
  const [, { setMediaOverlay }] = useMediaOverlayContext();
  const [, { sendClick }] = useEventsContext();

  return (
    <>
      <div class="media-container">
        <Show
          when={
            props.landing.field_video && props.landing.field_video.length > 0
          }
        >
          <div class="media-button" data-test="btn-video">
            <button
              class="btn btn-icon"
              type="button"
              aria-label={
                props.landing.field_video!.length > 1 ? "Vidéos" : "Vidéo"
              }
              onClick={() => {
                sendClick("btn-videos", "first-screen");
                setMediaOverlay("video", props.landing.field_video);
              }}
            >
              <i aria-hidden="true" class="cog-icon">
                <IconPlayCircle />
              </i>
            </button>
            <Show
              fallback={<span class="tip">Vidéo</span>}
              when={props.landing.field_video!.length > 1}
            >
              <span class="count">{props.landing.field_video!.length}</span>
              <span class="tip">Vidéos</span>
            </Show>
          </div>
        </Show>
        <Show
          when={props.landing.field_3ds && props.landing.field_3ds.length > 0}
        >
          <div class="media-button" data-test="btn-3d">
            <button
              class="btn btn-icon"
              type="button"
              aria-label="3D"
              onClick={() => {
                sendClick("btn-virtual-tour", "first-screen");
                setMediaOverlay("landing3ds", props.landing);
              }}
            >
              <i aria-hidden="true" class="cog-icon threed">
                <Icon3dRotation />
              </i>
            </button>
            <Show
              fallback={<span class="tip">3D</span>}
              when={props.landing.field_3ds!.length > 1}
            >
              <span class="count">{props.landing.field_3ds!.length}</span>
              <span class="tip">Maquette 3D</span>
            </Show>
          </div>
        </Show>
        <Show
          when={
            props.landing.field_gallery_disp &&
            props.landing.field_image_desktop &&
            props.landing.field_image_desktop!.length > 0
          }
        >
          <div class="media-button" data-test="btn-gallery">
            <button
              class="btn btn-icon"
              type="button"
              aria-label="Galerie"
              onClick={() => {
                sendClick("btn-gallery", "first-screen");
                setMediaOverlay("images", props.landing.field_image_desktop);
              }}
            >
              <i aria-hidden="true" class="cog-icon">
                <IconPhotoLibrary />
              </i>
            </button>
            <span class="count">
              {props.landing.field_image_desktop!.length}
            </span>
            <span class="tip">Galerie</span>
          </div>
        </Show>
        <Switch>
          <Match when={props.landing.field_programs_disp_map}>
            <div class="media-button" data-test="btn-map">
              <button
                class="btn btn-icon"
                type="button"
                aria-label="Carte"
                onClick={() => {
                  sendClick("btn-map", "first-screen");
                  setMediaOverlay("map", props.landing.programs);
                }}
              >
                <i aria-hidden="true" class="cog-icon">
                  <IconLocationOn />
                </i>
              </button>
              <span class="tip">Carte</span>
            </div>
          </Match>
        </Switch>
        <Show when={props.landing.field_neighborhood_map?.uri?.url}>
          <div class="media-button" data-test="btn-neighborhood">
            <button
              class="btn btn-icon"
              type="button"
              aria-label="Plan du quartier"
              onClick={() => {
                sendClick("btn-neighborhood-map", "first-screen");
                setMediaOverlay(
                  "images",
                  props.landing.field_neighborhood_map?.uri?.url,
                );
              }}
            >
              <i aria-hidden="true" class="cog-icon">
                <IconDescription />
              </i>
            </button>
            <span class="tip">Plan du quartier</span>
          </div>
        </Show>
      </div>
    </>
  );
}
